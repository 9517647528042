import Vue from "vue";
import Component from "vue-class-component";
import { HasFormErrors } from "@/mixins/has-form-errors";
import { Ref, Mixins, Watch } from "vue-property-decorator";
import { AsbestosType, EkType, EpcType, FueltankType, ImmoSignType, Partner, PartnerType } from "@/store/modules/partner.store";
import { bus } from "@/main";
import { Action, Getter } from "vuex-class";
import { IndexMandatePayload, Mandate } from "@/store/modules/mandate.store";
import { IndexDocumentPayload, DocumentType } from "@/store/modules/document.store";
import { VastgoedexpertsProduct } from "@/store/modules/vastgoedexperts.store";
import { formatDate } from "@/utils/formatters";
import { HasSellers } from "@/mixins/has-sellers";
import { HasSellersWithRelation } from "@/mixins/has-sellers-with-relation";
import { HasPickupKeys, PickupKeyPayload } from "../partnerRequests/has-pickup-keys";

interface CreateVgePayload extends PickupKeyPayload {
  activity_id: null | number;
  partner_id: null | number;
  preferred_date: null | string;
  qty_units: number;
  square_meters?: null | number;
  layout_id?: null | number;
  setup?: null | number;
  design?: null | number;
  placement_detail?: null | number;
  color?: null | number;
  electricity?: number;
  construction_year?: null | number;
  subtype: null | AsbestosType | EkType | EpcType | FueltankType | ImmoSignType;
  remarks?: string;
  accessibility?: string;
  invoice_data?: {
    first_name: string;
    last_name: string;
    phone: string;
    email: string;
    billing_address: {
      street: string;
      number: string;
      bus: string;
      zip: string;
      country_code: string;
    };
    vat_number: string;
    vat_country_code: string;
  };
}

@Component({
  components: {
    Spinner: () => import("@/components/general/spinner.vue"),
  },
})
export class HasVgeModal extends Mixins(HasFormErrors, HasSellersWithRelation, HasPickupKeys) {
  @Getter("activity/viewing") activity!: Activity;
  @Getter("property/viewing") property!: Property;
  @Getter("mandate/all") mandates!: Mandate[];

  @Action("vastgoedexperts/readStatus") readStatus!: (payload: { activity_id: number; partner_id: number }) => Promise<VastgoedexpertsProduct>;
  @Action("property/read") readProperty!: (payload: { activity_id: number }) => Promise<Property>;
  @Action("mandate/index") indexMandates!: (payload: IndexMandatePayload) => Promise<Mandate[]>;
  @Action("document/index") indexDocuments!: (payload: IndexDocumentPayload) => Promise<Document[]>;

  @Ref() form!: FormClass;

  formatDate = formatDate;

  AsbestosType = AsbestosType;

  EkType = EkType;

  EpcType = EpcType;

  FueltankType = FueltankType;

  asap = false;

  show = false;

  partner: Partner | null = null;

  partnerTypes = PartnerType;

  payload: CreateVgePayload = {
    activity_id: null,
    partner_id: null,
    layout_id: null,
    preferred_date: null,
    pick_up_key: 1,
    pick_up_key_data: {
      phone: "",
      address: {
        street: "",
        number: "",
        bus: "",
        zip: "",
      },
    },
    qty_units: 1,
    square_meters: null,
    electricity: 1,
    construction_year: null,
    subtype: null,
    setup: null,
    design: null,
    placement_detail: null,
    color: null,
    remarks: "",
    accessibility: "",
    invoice_data: {
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      billing_address: {
        street: "",
        number: "",
        bus: "",
        zip: "",
        country_code: "",
      },
      vat_number: "",
      vat_country_code: "",
    },
  };

  modalType = "";

  documents: DocumentType[] = [];

  uploadedDocuments: Document[] = [];

  loadingDocuments = false;

  callback?: (partner: Partner) => void = undefined;

  mounted() {
    bus.$off(this.modalType);
    bus.$on(this.modalType, async (partner: Partner, callback?: (partner: Partner) => void) => {
      let promises = [];

      this.show = true;

      this.partner = Object.assign({}, partner);

      this.callback = callback;

      this.resetPayload();

      if (!this.property || this.property.activity_id !== this.activity.id) {
        promises.push(await this.readProperty({ activity_id: this.activity.id }));
      }

      let mandate = this.mandates.find((mandate: Mandate) => mandate.activity_id === this.activity.id);

      if (!mandate) {
        promises.push(await this.indexMandates({ s: { updated_at: "desc" }, per_page: 1, q: { and: { activity_id: this.activity.id } } }));
      }

      await Promise.all(promises);

      this.handlePrefillData();

      this.fetchDocuments();

      this.handleAfterOpen();
    });
  }

  handleAfterOpen() {}

  resetPayload() {
    this.payload = {
      activity_id: this.activity.id,
      partner_id: this.partner!.id,
      preferred_date: null,
      pick_up_key: 0,
      pick_up_key_data: {
        phone: "",
        address: {
          street: "",
          number: "",
          bus: "",
          zip: "",
        },
      },
      qty_units: 1,
      square_meters: null,
      electricity: 1,
      construction_year: null,
      subtype: null,
      remarks: "",
      accessibility: "",
      invoice_data: {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        billing_address: {
          street: "",
          number: "",
          bus: "",
          zip: "",
          country_code: "",
        },
        vat_number: "",
        vat_country_code: "",
      },
    };
  }

  handlePrefillData() {
    this.payload.construction_year = this.property?.characteristics?.building?.construction_year;

    let mandate = this.mandates.find((mandate: Mandate) => mandate.activity_id === this.activity.id);

    this.prefillKeysAvailable(mandate);

    this.prefillLivableArea();
  }

  prefillLivableArea() {
    // Can be inherit by the child
  }

  handleKeyContactChanged(payload: any) {
    this.payload.remarks = "";
    if (!payload.first_name && !payload.last_name && !payload.email && !payload.phone) {
      return;
    }

    let contactInfo = "Contactgegevens voor de sleutel: \n";
    contactInfo += `\n${payload.first_name} ${payload.last_name}`;
    if (payload.email) {
      contactInfo += `\n${payload.email}`;
    }

    if (payload.phone) {
      contactInfo += `\n${payload.phone}`;
    }

    this.payload.remarks = contactInfo;
  }

  sanitizePayload() {
    if (!this.payload.pick_up_key) {
      delete this.payload.pick_up_key_data;
    }

    if (this.payload.preferred_date) {
      this.payload.preferred_date = this.formatDate(this.payload.preferred_date, "YYYY-MM-DD");
    }

    if (!this.payload.remarks) {
      delete this.payload.remarks;
    }

    if (!this.payload.accessibility) {
      delete this.payload.accessibility;
    }

    if (this.payload.square_meters === null) {
      delete this.payload.square_meters;
    }
  }

  async fetchDocuments() {
    if (!this.documents.length) {
      return;
    }

    this.loadingDocuments = true;

    this.uploadedDocuments = await this.indexDocuments({ per_page: 99, q: { and: { or: { type: this.documents }, activity_id: Number(this.$route.params.id) } } });

    this.loadingDocuments = false;
  }

  handleClose() {
    this.clearErrors();

    this.show = false;
  }

  get matchedUploadedDocuments() {
    return this.uploadedDocuments.map((document) => document.type);
  }

  @Watch("asap")
  handleAsapChange(newValue: boolean) {
    if (newValue) {
      this.payload.preferred_date = null;
    }
  }
}
