
import { Action } from "vuex-class";
import { Component, Mixins } from "vue-property-decorator";
import { FormBase, FormError, FormInput, FormSelect, FormDate, FormSubmit, FormTextarea, FormCheckbox, FormYesNo } from "@/components/forms";
import { formatErrors } from "@/utils/formatters";
import { HasVgeModal } from "@/mixins/vge/has-vge-modal";
import { fueltankTypeOptions } from "@/utils/select-options";
import { FueltankType } from "@/store/modules/partner.store";

@Component({
    components: {
        LayoutModal: () => import("@/layouts/components/modal.vue"),
        FormBase,
        FormInput,
        FormDate,
        FormTextarea,
        FormSelect,
        FormError,
        FormCheckbox,
        FormYesNo,
        FormSubmit,
    },
})
export default class AddVgeFueltankModal extends Mixins(HasVgeModal) {
    @Action("vastgoedexperts/createFueltank") createFueltank!: (payload: any) => Promise<void>;

    fueltankTypeOptions = fueltankTypeOptions;

    FueltankType = FueltankType;

    modalType = "show-vge-add-fueltank";

    async submit(form: FormClass) {
        try {
            this.sanitizePayload();

            await this.createFueltank(this.payload);

            this.handleClose();

            if (this.callback && this.partner) {
                this.callback(this.partner);
            }

            this.$toast.open({ message: "Keuring aangevraagd", type: "success" });
        } catch (e) {
            this.errorResponse = formatErrors(e);
        } finally {
            form.reset();
        }
    }
}
